import SectionSlider from "../../sections/home/section-slider";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionFeatures from "../../sections/home/section-features";
import SectionDeliveryJoin from "../../sections/home/section-driver-join-us";
import SectionVendorJoin from "../../sections/home/section-vendor-join";
import SectionAboutUs from "../../sections/home/section-about-us";
import SectionContactUs from "../../sections/home/section-contact-us";
import SectionFAQ from "../../sections/home/section-FAQ";

function HomePage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            <div id="slider-section">
                <SectionSlider />
            </div>
            <div id="features-section">
                <SectionFeatures/>
            </div>
            <div id="delivery-section">
                <SectionDeliveryJoin/>
            </div>
            <div id="vendor-section">
                <SectionVendorJoin/>
            </div>
            <div id="aboutus-section">
                <SectionAboutUs/>
            </div>
            <div id="contactus-section">
                <SectionContactUs/>
            </div>
            <div id="faq-section">
                <SectionFAQ/>
            </div>
        </>
    )
}

export default HomePage;

import { NavLink } from "react-router-dom";
import KBImage from "../../elements/kb-img";


function Footer() {
    return (
        <>
            <footer className="site-footer footer-dark">
                {/* FOOTER BLOCKES START */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* ABOUT COMPANY */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix p-b15">
                                        <NavLink to="/index" className="sx-text-primary"><KBImage src="images/footer-logo.ico" alt="#" /></NavLink>
                                    </div>
                                    <p> Thank you for choosing KulaBox, and we look forward to serving you!
                                    </p>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                        <div className="sx-f-call-section">
                                            <span>Contact us 24/7</span>
                                            <a href="tel:+55(66633)2577">+ 2547 ( 3375 )7517 </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* USEFUL LINK */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Useful Links</h4>
                                    <ul>
                                        <li><a href="#slider-section">Home</a></li>
                                        <li><a href="#features-section">Features</a></li>
                                        <li><a href="#aboutus-section">About Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/*contacts */}
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                                <div className="widget widget_info">
                                    <h4 className="sx-f-title">Our Contacts</h4>
                                    <ul>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-phone" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="tel:+2547(2860)7774">+ 2547 ( 2860 ) 7774</a>
                                                <a href="tel:+55(66633)2577">+ 2547 ( 3375 )7517 </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>support@kulabox.com</p>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>

                                            <div className="widget-info-detail">
                                                <p>info@kulabox.com</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            {/*Support */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Support</h4>
                                    <ul>
                                        <li>
                                            <a href="https://kulabox.co.ke/privacy/policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="https://kulabox.co.ke/pages/terms">Terms and Conditions</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            {/*Sign ups */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Partner With Us</h4>
                                    <ul>
                                        <li>
                                            <a href="https://kulabox.co.ke/register/vendor">Store/Vendor Sign-Up</a>
                                        </li>
                                        <li>
                                            <a href="https://kulabox.co.ke/register/driver?lan=en">Driver Sign-Up</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="sx-f-bottom-section">
                    <div className="sx-f-social">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/">
                                    <i className="flaticon-facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/">
                                    <i className="flaticon-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/">
                                    <i className="flaticon-linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/">
                                    <i className="flaticon-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sx-f-copy">
                        © 2024 by <NavLink >Hostech Solutions.</NavLink> All rights reserved.
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
            </footer>

        </>
    )
}

export default Footer;
import { NavLink } from "react-router-dom";
import KBImage from "../../elements/kb-img";
import { useState } from "react";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function Header() {

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(() => {
        loadScript("js/mobilenav.js")
    })

    return (
        <>
            <header className={"header-style1 site-header  mobile-sider-drawer-menu " + (isActive ? "active" : "")}>

                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/index">
                                        <KBImage src="images/Logo.jpg" alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                                        <li><a href="https://www.facebook.com/"><i className="feather-facebook" /> </a></li>
                                        <li><a href="https://twitter.com/"><i className="feather-twitter" /> </a></li>
                                        <li><a href="https://www.linkedin.com/"><i className="feather-linkedin" /> </a></li>
                                        <li><a href="https://www.instagram.com/"><i className="feather-instagram" /> </a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className="nav navbar-nav">
                                    <li className="active">
                                        <a href="#slider-section">Home</a>
                                    </li>
                                    <li>
                                        <a href="#features-section">Features</a>
                                    </li>
                                    <li>
                                        <a href="#aboutus-section">About Us</a>
                                    </li>
                                    <li>
                                        <a href="#contactus-section">Contact Us</a>
                                    </li>
                                    <li>
                                        <a href="#faq-section">FAQs</a>
                                    </li>
                                    <div className="sx-bnr-readmore" style={{ padding: '10px', textAlign: 'center' }}>
                                        <a href="https://kulabox.co.ke/" className="site-button sx-btn-primary" rel="noopener noreferrer">
                                            Admin/Store Login
                                        </a>
                                    </div>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
            </header >

        </>


    )
}

export default Header;
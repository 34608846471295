import { Route, Routes } from "react-router-dom";
import HomePage from "../app/components/home/index";

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/index" element={<HomePage />} />
            </Routes>
        </>
    )
}

export default AppRoutes;
import KBImage from "../../elements/kb-img";



function SectionVendorJoin() {
    return (
        <>
            <div className="sx-mv-bx1-content-wrap">
                <div className="row">
                    <div className="col-lg-6 col-md-12" style={{ paddingLeft: '30px' }}>
                    <div className="section-head ">
                        <div className="rounded-container">
                            <div className="sx-head-s-title ">VENDOR</div>
                        </div>
                    </div>
                        <div className="sx-about-bx1-content">
                            <h2 className="sx-title">Sell with us.</h2>
                            <span >Join the KulaBox team and become a part of our growing community of drivers! As a KulaBox driver, you'll enjoy the flexibility, competitive earnings, and the satisfaction of providing a valuable service to your community.</span>
                            <div className="sx-head-s-title" style={{ fontWeight: 'bold' }}>Benefits</div>

                        <ul className="sx-arrow-circle-checked">
                            <li>Full control of your vehicle</li>
                            <li>Choose your deliveries</li>
                            <li>Choose your own hours</li>
                            <li>Accurate navigation</li>
                            <li>Easy and fast payments</li>
                        </ul>

                        <div className="sx-head-s-title" style={{ fontWeight: 'bold' }}>How it works</div>

                        <ul className="sx-arrow-circle-checked">
                            <li>Receive delivery requests directly on the app                           </li>
                            <li>Pick up and deliver orders to customers</li>
                            <li>View earnings and track your progress</li>
                            <li>Fast payout options</li>
                        </ul>
        
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-mv-bx1-media">
                            <div className="sx-mv-img-outer">
                                <div className="sx-mv-img-section-2">
                                    <KBImage src="images/store.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionVendorJoin;

import { publicUrlFor } from "../../../globals/constants";
import { register } from 'swiper/element/bundle';
import KBImage from "../../elements/kb-img";
register();

function SectionSlider() {
    return (
        <>
            <div className="sx-bnr-2-wrap-outer home-2-slider">
                {/* swiper slides */}
                <swiper-container
                    loop="true"
                    space-between="30"
                    effect="fade"
                    class="swiper-wrapper"
                >
                    <swiper-slide
                        class="sx-bnr-2-wrap swiper-slide overlay-overlay"
                        style={{
                            backgroundImage: `url(${publicUrlFor("images/slider.jpg")})`,
                            paddingTop: '40px',
                        }}
                    >


                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-2-content">
                                <span className="sx-bnr-2-small-title">Welcome to Kulabox</span>
                                <h2 className="sx-bnr-2-large-title">The Future of Ordering Foods, Parcel, Grocery, Taxi and More.</h2>
                                <div className="sx-bnr-2-info">KulaBox is a multi-service delivery app that allows you to order food, parcel, grocery, taxi and more from your favourite local stores and restaurants..</div>

                                <span className="sx-bnr-2-info">Download apps now!</span>
                                <ul style={{
                                    display: 'flex',
                                    listStyleType: 'none',
                                    padding: 0,
                                    margin: 0,
                                    alignItems: 'center',
                                }}>
                                    <li style={{
                                        marginRight: '10px',
                                        flexShrink: 3,
                                    }}>
                                        <a href="https://play.google.com/store/apps/details?id=com.hostechsolutions.kulabox" target="_blank" rel="noopener noreferrer">
                                            <KBImage
                                                src="images/playstore.png"
                                                alt="Get it on Google Play"
                                                style={{ width: '250px', height: 'auto' }}
                                            />
                                        </a>
                                    </li>
                                    <li style={{ flexShrink: 3 }}>
                                        <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
                                            <KBImage
                                                src="images/appstore.png"
                                                alt="Download on the App Store"
                                                style={{ width: '215px', height: 'auto' }}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div style={{padding:'30px', display: 'flex', justifyContent: 'center' }}>
                                <KBImage
                                    src="images/intro.png"
                                    alt="intro screens"
                                    style={{ width: 'auto', height: 'auto' }}
                                />
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </>
    )
}

export default SectionSlider;
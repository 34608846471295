
import KBImage from "../../elements/kb-img";

function SectionContactUs() {
    return (
        <>
            <div className="row">
                <div style={{ paddingTop: '20px', paddingLeft: '30px' }}>

                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Contact Us</h2>
                        <span >We're always here to assist you with any questions or concerns you may have. Whether you're seeking guidance with our services, providing valuable feedback, or simply want to get in touch with us, we're ready and eager to help. Our dedicated support team strives to ensure that every inquiry is addressed promptly and thoroughly.
                            You can reach us through multiple channels, ensuring that support is always just a message away. Choose the method that best suits your needs.
                            Your satisfaction is our priority, and we look forward to resolving any issues, answering questions, or hearing your thoughts!</span>

                        {/* Contact us START */}
                        <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
                            <div className="container">
                                {/* GOOGLE MAP & CONTACT FORM */}
                                <div className="section-content">
                                    {/* CONTACT FORM*/}
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 col-md-12  m-b30">
                                            <div className="contact-info">
                                                {/* TITLE START */}
                                                <div className="section-head left">
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="sx-about-bx4-media">
                                                            <div className="sx-ab4-img-outer">
                                                                <div className="sx-ab4-media">
                                                                    <KBImage src="images/contact-us.png" alt="media" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="row sx-contact-form-icon-bx">
                                                    <div className="col-lg-6 col-md-6 m-b30">
                                                        <div className="sx-icon-bx-5">
                                                            <div className="sx-media">
                                                                <i className="flaticon-telephone" />
                                                            </div>
                                                            <div className="sx-icon-bx-info">
                                                                <a  className="sx-icon-bx-title-info">Contact Phone</a>
                                                                <a  className="sx-icon-bx-title">+ 2547 ( 2860 ) 7774</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 m-b30">
                                                        <div className="sx-icon-bx-5">
                                                            <div className="sx-media">
                                                                <i className="flaticon-email-2" />
                                                            </div>
                                                            <div className="sx-icon-bx-info">
                                                                <a  className="sx-icon-bx-title-info">Contact Mail</a>
                                                                <a  className="sx-icon-bx-title">support@kulabox.co.ke</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 m-b30">
                                                        <div className="sx-icon-bx-5">
                                                            <div className="sx-media">
                                                                <i className="flaticon-clock" />
                                                            </div>
                                                            <div className="sx-icon-bx-info">
                                                                <a  className="sx-icon-bx-title-info">Office Time</a>
                                                                <a  className="sx-icon-bx-title">
                                                                    Mon- Fri: 7.00 AM - 10.00 PM
                                                                </a>
                                                                <a  className="sx-icon-bx-title">
                                                                    Sat-Sun: 8.00AM - 10.00 PM
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 m-b30">
                                            <div className="contact-home1-right">
                                                <div className="contact-home-1-form sx-bg-light">
                                                    <h3 className="sx-title">Get In Touch</h3>
                                                    <form className="cons-contact-form2 form-transparent" method="post">
                                                        <div className="input input-animate">
                                                            <input type="text" name="username" id="name" required placeholder="Your Name" />
                                                            <span className="spin" />
                                                        </div>
                                                        <div className="input input-animate">
                                                            <input type="email" name="email" id="email" required placeholder="Your Email" />
                                                            <span className="spin" />
                                                        </div>
                                                        <div className="input input-animate">
                                                            <input type="text" name="phone" id="Phone" required placeholder="Phone Number" />
                                                            <span className="spin" />
                                                        </div>
                                                        <div className="input textarea input-animate">
                                                            <textarea name="message" id="message" required placeholder="Your Message" defaultValue={""} />
                                                            <span className="spin" />
                                                        </div>
                                                        <div className="sx-btn-center text-center p-t10">
                                                            <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                                                Send Message
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Contact us END */}
                    </div>
                </div>
            </div>
        </>
    )
}



export default SectionContactUs;
import KBImage from "../../elements/kb-img";



function SectionAboutUs() {
    return (
        <>
            <div className="sx-mv-bx1-content-wrap">
                <div className="row">
                    <div className="col-lg-6 col-md-12" style={{ paddingLeft: '30px' }}>
                        <div className="sx-about-bx1-content">
                            <h2 className="sx-title">About Us.</h2>
                            <span >At KulaBox, we believe in making life simpler and more convenient. As a comprehensive super app, KulaBox integrates food delivery, parcel services, grocery shopping, and taxi hailing all in one place, ensuring you have access to essential services right at your fingertips.
                                Our mission is to connect communities through innovative technology, providing seamless access to everyday services. We strive to offer reliable, efficient, and high-quality solutions that cater to the diverse needs of our users..</span>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-mv-bx1-media">
                            <div className="sx-mv-img-outer">
                                <div className="sx-mv-img-section-2">
                                    <KBImage src="images/team.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionAboutUs;
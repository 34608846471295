import KBImage from "../../elements/kb-img";



function SectionFAQ() {
    return (
        <>
            <div className="sx-mv-bx1-content-wrap">
                <div className="row">
                    <div  style={{ paddingLeft: '30px' }}>
                        <div className="sx-about-bx1-content">
                            <h2 className="sx-title">FAQs.</h2>

                            <span >Need help? Check out our FAQs to find answers to your questions..</span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    <div className="sx-mv-bx1-media">
                                    <div className="sx-mv-img-outer">
                                        <div className="sx-mv-img-section-2">
                                            <KBImage src="images/faq.jpg" alt="image" />
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <>
            <div className="aon-faq-content" style={{ paddingLeft: '30px' }}>
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                    How do I create an account?
                                    </button>
                                    <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        To create an account, navigate to our registration page by clicking the "Sign Up" button. Fill out the required information, such as your name, email address, and a password. Once submitted, you'll receive a confirmation email. Click the link in the email to activate your account and you're all set! </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                    How do I place an order?
                                    </button>
                                    <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        Placing an order is simple! Browse through our catalog or use the search bar to find the products you’re interested in. Once you've selected the item, click "Add to Cart." After you're done shopping, go to your cart and click "Checkout." Follow the prompts to enter your shipping and payment information, and your order will be placed.   </div>
                                    </div>
                                </div>
                                {/*Three*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3" aria-expanded="false">
                                    What can I order?
                                    </button>
                                    <div id="FAQ3" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        You can order a wide range of products and services from our platform. Our offerings include everything from physical goods like electronics and clothing to digital products and services. Explore the different categories to find exactly what you're looking for.  </div>
                                    </div>
                                </div>
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                    How do I find what I want?
                                    </button>
                                    <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        You can easily find what you’re looking for by using the search bar at the top of the page. Enter the name or a keyword related to the product or service, and we’ll show you the most relevant results. Additionally, you can browse by categories or use filters to narrow down your options. </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
                                    How do I withdraw my earnings?
                                    </button>
                                    <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                        To withdraw your earnings, go to your account dashboard and navigate to the "Earnings" section. From there, select the "Withdraw" option, choose your preferred withdrawal method (such as bank transfer or PayPal), and enter the necessary details. Your request will be processed, and you’ll receive the funds within the specified timeframe. </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

                </div>
            </div>
        </>
    )
}

export default SectionFAQ;

import KBImage from "../../elements/kb-img";

function SectionDeliveryJoin() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <KBImage src="images/delivery-boy.png" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="col-lg-6 col-md-12" style={{ paddingTop: '20px',paddingLeft:'30px' }}>
                
                    <div className="sx-about-bx4-content">
                    <div className="section-head ">
                        <div className="rounded-container">
                            <div className="sx-head-s-title ">DRIVER</div>
                        </div>
                    </div>
                        <h2 className="sx-title">Drive for us</h2>
                        <p style={{ paddingRight: '20px' }}>
                            Join the KulaBox team and become a part of our growing community of drivers!
                            As a KulaBox driver, you'll enjoy the flexibility, competitive earnings,
                            and the satisfaction of providing a valuable service to your community.
                        </p>
                        <div className="sx-head-s-title" style={{ fontWeight: 'bold' }}>Benefits</div>

                        <ul className="sx-arrow-circle-checked">
                            <li>Full control of your vehicle</li>
                            <li>Choose your deliveries</li>
                            <li>Choose your own hours</li>
                            <li>Accurate navigation</li>
                            <li>Easy and fast payments</li>
                        </ul>

                        <div className="sx-head-s-title" style={{ fontWeight: 'bold' }}>How it works</div>

                        <ul className="sx-arrow-circle-checked">
                            <li>Receive delivery requests directly on the app                           </li>
                            <li>Pick up and deliver orders to customers</li>
                            <li>View earnings and track your progress</li>
                            <li>Fast payout options</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}



export default SectionDeliveryJoin;
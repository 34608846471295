import { NavLink } from "react-router-dom";
import KBImage from "../../elements/kb-img";

function SectionFeatures() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="rounded-container">
                            <div className="sx-head-s-title ">Features</div>
                        </div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">KulaBox your go-to super app!</h2>
                        </div>
                        <p className="sx-bnr-2-info">Discover the powerful features that make KulaBox your go-to super app for all your daily needs from food - parcel - grocery - taxi and more.</p>

                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                                {/* Block one */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary">  <KBImage
                                                    src="images/ordering.png"
                                                    alt="ordering"

                                                /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Ordering</h4>
                                                <p>Enjoy your favorite meals from local restaurants delivered straight to your door. Choose from a wide variety of cuisines and restaurants to satisfy any craving.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Two */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"> <KBImage
                                                    src="images/delivery.png"
                                                    alt="ordering"

                                                /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Fast Delivery</h4>
                                                <p>Get items delivered faster to your doorstep with ease. Our service combines swift delivery times with unmatched convenience, ensuring your orders arrive quickly and reliably. </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Block Three */}
                                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><KBImage
                                                    src="images/save.png"
                                                    alt="ordering"

                                                /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Cheaper & Better</h4>
                                                <p>Ordering items shouldn't be expensive. At KulaBox, you can find quality products at affordable prices. Discover how affordable and rewarding shopping can be with KulaBox.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionFeatures;